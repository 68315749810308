import React from "react"
import { Link } from "gatsby"
import images from "../../../assets/images"
import "../../Singleformations/Singleformations.css"

const Popup = ({ user: { email, logo } }) => {
  return (
    <>
      <div className="Popup singleFormation" id="Modal" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header px-5">
              <span>
                <img
                  src={images.logoSeformerPopup}
                  alt="offres de formation et de stage à La Réunion"
                />
              </span>
              <span className="mx-5 mt-3">avec</span>
              <span className="span-image-logo">
                <img className="image-logo" src={logo} alt={""} />
              </span>
            </div>
            <div className="modal-body px-105 pb-4 pt-0">
              <h3 className="Nomdanspopup" style={{ margin: "25px" }}>
                Votre compte vient d'être créé, vos identifiants sont
                envoyés dans votre boite mail : {email}
              </h3>
              <div className="row justify-content-end align-items-center btnpopup mx-2 my-2 pt-3">
                <Link to={`/`}>
                  <button className="btn-send btn btn-link">Retour</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="Fondpopup"> </div>
      </div>
    </>
  )
}

export default Popup
