import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"
import SiteMetadata from "../componets/SiteMetadata/SiteMetadata"
import Header from "../componets/Header/Header"
import Footer from "../componets/Footer/Footer"
import CreerCompte from "../componets/CreerCompte/CreerCompte"
import {
  stripTags,
  translateSector,
  translateRegion,
  translateCity,
  translateTraining,
  translateFonction,
} from "../services/helpers"
import "./globals.css"

export const query = graphql`
  query {
    hasura {
      seformer_db_agency {
        agency_id
        name
        db_public_info_agency {
          public_info_id
          dbMediaByMainLogoId {
            link_full
          }
        }
        db_articles {
          article_id
          status
          title
        }
      }
      seformer_db_region(order_by: { name: asc }) {
        region_id
        name
        db_country {
          country_id
          name
        }
      }
      seformer_db_city(order_by: { name: asc }) {
        city_id
        name
        postal_code
        db_region {
          region_id
          name
        }
      }
      seformer_db_training_type(order_by: { name: asc }) {
        name
        type_id
      }
      seformer_db_sector(order_by: { name: asc }) {
        name
        sector_id
        db_media {
          link_full
          media_id
        }
      }
      seformer_db_level(order_by: { name: asc }) {
        level_id
        name
        image {
          link_full
          media_id
        }
      }
      seformer_db_public_admis {
        public_admis_id
        name
      }
      seformer_db_mobilization_device {
        mobilization_device_id
        name
      }
      seformer_db_fonction(order_by: { fonction_id: desc }) {
        name
        fonction_id
      }
      seformer_db_agency_type(order_by: { agency_type_id: desc }) {
        name
        agency_type_id
      }
    }
    logo: file(
      absolutePath: {
        regex: "/images/logo_seformer-f59ecb37be2dc067b743d7ee0a113952.webp/"
      }
    ) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default function Home(props) {
  const [allMetiersVises, setAllMetiersVises] = useState([])
  const { data } = props;

  const metas = {
    title: "Création compte",
    metatitle: "",
    description: "",
    keywords: "",
  }

  const regions = data.hasura.seformer_db_region.map(region =>
    translateRegion(region)
  )
  const cities = data.hasura.seformer_db_city.map(city => translateCity(city))
  const sectors = data.hasura.seformer_db_sector.map(sector =>
    translateSector(sector)
  )
  const fonctions = data.hasura.seformer_db_fonction.map(fonction =>
    translateFonction(fonction)
  )
  const agence_types = data.hasura.seformer_db_agency_type.map(type => ({
    id: type.agency_type_id,
    name: type.name,
  }))

  return (
    <div className="content_page_creation_mon_compte">
      <SiteMetadata metas={metas} />
      <Header {...props} logo={data.logo} />
      <CreerCompte
        regions={regions}
        cities={cities}
        fonctions={fonctions}
        sectors={sectors}
        agence_types={agence_types}
      />
      <Footer />
    </div>
  )
}
