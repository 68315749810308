import React, { useState, useEffect } from "react"
import { TextField, Button } from "@material-ui/core"
import { Form, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import LoadingOverlay from "react-loading-overlay"
import CustomUploadPhoto from "./UploadPhoto/UploadPhoto"
import ResultaPop from "./ResultPop/ResultPop"
import "./CreerCompte.css"

function CreerCompte({ regions, cities, fonctions, sectors, agence_types }) {
  const [dep, setDep] = useState({
    postalCode: "",
    regionId: 0,
    cityId: 0,
  })
  const [image, setImage] = useState(null)
  const [kbis, setKbis] = useState(null)
  const [ville, setVille] = useState([])
  const [emailError, setEmailError] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [fileSubmit, setFileSubmit] = useState(false)
  const [isResult, setResult] = useState(false)
  const [logos, setLogos] = useState([])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      entreprise_departement: "",
      entreprise_societe_name: "",
      entreprise_adresse: "",
      entreprise_cp: "",
      entreprise_ville: "",
      entreprise_contact_nom: "",
      entreprise_contact_prenom: "",
      entreprise_contact_fonction: "",
      entreprise_email: "",
      entreprise_phone: "",
      entreprise_logo: "",
      entreprise_site_web: "",
      entreprise_domaine_activite: "",
      entreprise_type_entreprise: "",
      entreprise_presentation_entreprise: "",
      entreprise_nb_formation: "",
      entreprise_nb_personne_forme_par_an: "",
      entreprise_taux_reussite: "",
      entreprise_facture_raison_social: "",
      entreprise_facture_siret: "",
      entreprise_facture_kbis: "",
      entreprise_facture_adresse_siege: "",
      entreprise_facture_adresse_complement: "",
      entreprise_facture_ville: "",
      entreprise_facture_pays: "",
      entreprise_facture_cp: "",
      entreprise_facture_contact_fonction: "",
      entreprise_facture_contact_nom: "",
      entreprise_facture_contact_prenom: "",
      entreprise_facture_contact_email: "",
      entreprise_facture_contact_phone: "",
      entreprise_abonnement_name: "",
      entreprise_abonnement_date_debut: "",
      entreprise_abonnement_credit: "",
    },
    validationSchema: Yup.object().shape({
      entreprise_societe_name: Yup.string().required("Ce champ est requis"),
      entreprise_departement: Yup.number()
        .typeError("Ce champ est requis")
        .required("Ce champ est requis"),
      entreprise_adresse: Yup.string().required("Ce champ est requis"),
      entreprise_cp: Yup.string()
        .matches(/^[0-9]{5}/, { message: "Doit contenir 5 chiffres" })
        .required(" Ce champ est requis"),
      entreprise_ville: Yup.number()
        .typeError("Ce champ est requis")
        .required("Ce champ est requis"),
      entreprise_contact_nom: Yup.string().required("Ce champ est requis"),
      entreprise_contact_prenom: Yup.string().required("Ce champ est requis"),
      entreprise_email: Yup.string()
        .email("Adresse e-mail non valide")
        .required("Ce champ est requis"),
      entreprise_phone: Yup.string().matches(
        /^[+]*[0-9]{10}$/g,
        "Numéro de télephone non valide"
      ),
      entreprise_domaine_activite: Yup.number()
        .typeError("Ce champ est requis")
        .required("Ce champ est requis"),
      entreprise_type_entreprise: Yup.number()
        .typeError("Ce champ est requis")
        .required("Ce champ est requis"),
      entreprise_facture_adresse_siege: Yup.string().required(
        "Ce champ est requis"
      ),
      entreprise_facture_cp: Yup.string()
        .matches(/^[0-9]{5}/, { message: "Doit contenir 5 chiffres" })
        .required(" Ce champ est requis"),
      entreprise_facture_ville: Yup.string().required("Ce champ est requis"),
      entreprise_facture_pays: Yup.string().required("Ce champ est requis"),
      entreprise_facture_contact_nom: Yup.string().required(
        "Ce champ est requis"
      ),
      entreprise_facture_contact_prenom: Yup.string().required(
        "Ce champ est requis"
      ),
      entreprise_facture_contact_email: Yup.string().email(
        "Adresse e-mail non valide"
      ),
      entreprise_facture_contact_phone: Yup.string().matches(
        /^[+]*[0-9]{10}$/g,
        "Numéro de télephone non valide"
      ),
      entreprise_logo: Yup.string().required("Ce champ est requis"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      if (logos.length) {
        setImage(logos[0])
      }

      const dataAgency = {
        ...values,
        main_logo_id: image?.image_id,
        logos,
      }

      const { data } = await axios.post("/.netlify/functions/create-agence", {
        ...dataAgency,
      })

      if (data?.message !== "success") {
        const message = data.message
        alert(`Erreur! ${message}`)
        // if (/email/.test(message)) {
        //   setEmailError(true)
        // }
        if (/entreprise/.test(message)) {
          setNameError(true)
        }
      } else {
        setResult(true)
      }
      setSubmitting(false)
    },
  })

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik

  useEffect(() => {
    if (cities.length) {
      setVille(cities)
    }
  }, [cities])

  useEffect(() => {
    if (values.entreprise_departement) {
      const regionId = Number(values.entreprise_departement)
      setDep({ ...dep, regionId, postalCode: "" })
      setVille((cities || []).filter(city => city?.region?.id === regionId))
    }
  }, [values.entreprise_departement])

  useEffect(() => {
    if (values.entreprise_ville) {
      const cityId = Number(values.entreprise_ville)
      const city = (cities || []).find(city => city.id === cityId)
      setDep({ ...dep, cityId, postalCode: city?.postal_code || "" })
      setFieldValue("entreprise_cp", city?.postal_code)
    }
  }, [values.entreprise_ville])

  useEffect(() => {
    if (values.entreprise_ville) {
      setEmailError(false)
    }
  }, [values.entreprise_email])

  useEffect(() => {
    if (values.entreprise_societe_name) {
      setNameError(false)
    }
  }, [values.entreprise_societe_name])

  const onRemoveFile = index => {
    logos.splice(index, 1)
    setLogos([...logos])
  }

  const onUpload = files => {
    const file = files[0]
    if (!file) return
    const isPdf = file.type === "application/pdf"
    if (!isPdf) {
      setImage(file)
    }
    setFileSubmit(true)

    // reader
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = e => reject(e)
    })
      .then(async base64 => {
        const resFile = await axios.post("/.netlify/functions/upload-file", {
          buffer: base64,
          name: file.name,
          type: file.type,
        })

        if (!isPdf) {
          const item = {
            src: resFile.data.filepath,
            image_id: resFile.data.media_id,
          }
          setImage(item)
          logos.push(item)
          setLogos([...logos])
          setFieldValue("entreprise_logo", resFile.data.media_id)
        } else {
          setKbis({
            path: resFile.data.filepath,
            media_id: resFile.data.media_id,
          })
          setFieldValue("entreprise_facture_kbis", resFile.data.filepath)
        }
        setFileSubmit(false)
      })
      .catch(e => {
        console.error(e)
        setFileSubmit(false)
      })
  }

  return (
    <>
      {(isResult && (
        <ResultaPop
          user={{ email: values.entreprise_email, logo: image?.src }}
        />
      )) ||
        ""}
      <div className="container">
        <div className="row">
          <div className="content_page_creer_compte_outer">
            <LoadingOverlay
              active={isSubmitting || fileSubmit}
              spinner
              text={fileSubmit ? "Chargement fichier ..." : "Envoi..."}
            >
              <div className="content_page_creer_compte_inner">
                <FormikProvider value={formik}>
                  <Form autoComplete="off" onSubmit={handleSubmit}>
                    <h2 className="title-section text-center content-inscrive">
                      Inscrivez-
                      <span style={{ margin: "0", display: "inline" }}>
                        vous à l'espace Client
                      </span>
                    </h2>
                    <span className="text_champ_obligatoire">
                      *Champs obligatoires
                    </span>
                    <div className="content_interne_crea_compte">
                      <h3 className="titre3_section">
                        Contact <span>Principal</span>
                      </h3>
                      <div className="content_interne">
                        <div className="row">
                          <div className="col-md-4 col-lg-4 col-xs-12">
                            <fieldset>
                              <TextField
                                placeholder="Nom *"
                                {...getFieldProps("entreprise_contact_nom")}
                                error={Boolean(
                                  touched.entreprise_contact_nom &&
                                    errors.entreprise_contact_nom
                                )}
                                helperText={
                                  touched.entreprise_contact_nom &&
                                  errors.entreprise_contact_nom
                                }
                              />
                            </fieldset>
                          </div>
                          <div className="col-md-4 col-lg-4 col-xs-12">
                            <fieldset>
                              <TextField
                                placeholder="Prénom *"
                                {...getFieldProps("entreprise_contact_prenom")}
                                error={Boolean(
                                  touched.entreprise_contact_prenom &&
                                    errors.entreprise_contact_prenom
                                )}
                                helperText={
                                  touched.entreprise_contact_prenom &&
                                  errors.entreprise_contact_prenom
                                }
                              />
                            </fieldset>
                          </div>
                          <div className="col-md-4 col-lg-4 col-xs-12">
                            <fieldset>
                              <TextField
                                select
                                SelectProps={{ native: true }}
                                {...getFieldProps(
                                  "entreprise_contact_fonction"
                                )}
                              >
                                <option value={null}>Fonction</option>
                                {(
                                  (fonctions || []).map(fonction => ({
                                    label: fonction.name,
                                    value: fonction.id,
                                  })) || []
                                ).map((item, key) => (
                                  <option key={key} value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              </TextField>
                            </fieldset>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 col-lg-6 col-xs-12">
                            <fieldset>
                              <label className="label-form label-vide">
                                &nbsp;
                              </label>
                              <TextField
                                type="email"
                                placeholder="Email *"
                                {...getFieldProps("entreprise_email")}
                                error={Boolean(
                                  (touched.entreprise_email &&
                                    errors.entreprise_email) ||
                                    emailError
                                )}
                                helperText={
                                  (touched.entreprise_email &&
                                    errors.entreprise_email) ||
                                  (emailError && "Adresse email déjà utilisée")
                                }
                              />
                            </fieldset>
                          </div>
                          <div className="col-md-6 col-lg-6 col-xs-12">
                            <fieldset>
                              <label className="label-form label-vide">
                                &nbsp;
                              </label>
                              <TextField
                                placeholder="Téléphone"
                                {...getFieldProps("entreprise_phone")}
                                error={Boolean(
                                  touched.entreprise_facture_contact_phone &&
                                    errors.entreprise_facture_contact_phone
                                )}
                                helperText={
                                  touched.entreprise_facture_contact_phone &&
                                  errors.entreprise_facture_contact_phone
                                }
                              />
                            </fieldset>
                          </div>
                        </div>
                      </div>
                      <h3 className="titre3_section">
                        Information <span>sur le centre de formation</span>
                      </h3>
                      <div className="content_interne">
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-xs-12">
                            <fieldset>
                              <label className="label-form">Société *</label>
                              <TextField
                                {...getFieldProps("entreprise_societe_name")}
                                error={Boolean(
                                  (touched.entreprise_societe_name &&
                                    errors.entreprise_societe_name) ||
                                    nameError
                                )}
                                helperText={
                                  (touched.entreprise_societe_name &&
                                    errors.entreprise_societe_name) ||
                                  (nameError && "Nom entreprise déjà utilisé")
                                }
                              />
                            </fieldset>
                            <fieldset>
                              <label className="label-form">
                                Département *
                              </label>
                              <TextField
                                select
                                SelectProps={{ native: true }}
                                {...getFieldProps("entreprise_departement")}
                                error={Boolean(
                                  touched.entreprise_departement &&
                                    errors.entreprise_departement
                                )}
                                helperText={
                                  touched.entreprise_departement &&
                                  errors.entreprise_departement
                                }
                              >
                                <option value={null}>Département</option>
                                {(
                                  (regions || []).map(region => ({
                                    label: region.name,
                                    value: region.id,
                                  })) || []
                                ).map((item, key) => (
                                  <option key={key} value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              </TextField>
                            </fieldset>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4 col-lg-4 col-xs-12">
                            <fieldset>
                              <label className="label-form">Adresse *</label>
                              <TextField
                                {...getFieldProps("entreprise_adresse")}
                                error={Boolean(
                                  touched.entreprise_adresse &&
                                    errors.entreprise_adresse
                                )}
                                helperText={
                                  touched.entreprise_adresse &&
                                  errors.entreprise_adresse
                                }
                              />
                            </fieldset>
                          </div>
                          <div className="col-md-4 col-lg-4 col-xs-12">
                            <fieldset>
                              <label className="label-form">
                                Code postal *
                              </label>
                              <TextField
                                {...getFieldProps("entreprise_cp")}
                                error={Boolean(
                                  (touched.entreprise_ville &&
                                    errors.entreprise_ville) ||
                                    (touched.entreprise_cp &&
                                      errors.entreprise_cp)
                                )}
                                helperText={
                                  ((touched.entreprise_ville &&
                                    errors.entreprise_ville) ||
                                    (touched.entreprise_cp &&
                                      errors.entreprise_cp)) &&
                                  errors.entreprise_cp
                                }
                                inputProps={{
                                  maxLength: 5,
                                  pattern: "/[0-9]{5}/",
                                  type: "number",
                                }}
                              />
                            </fieldset>
                          </div>
                          <div className="col-md-4 col-lg-4 col-xs-12">
                            <fieldset>
                              <label className="label-form">Ville *</label>
                              <TextField
                                select
                                SelectProps={{
                                  native: true,
                                  name: "entreprise_ville",
                                }}
                                {...getFieldProps("entreprise_ville")}
                                error={Boolean(
                                  touched.entreprise_ville &&
                                    errors.entreprise_ville
                                )}
                                helperText={
                                  touched.entreprise_ville &&
                                  errors.entreprise_ville
                                }
                              >
                                <option value={null}>Ville *</option>
                                {(
                                  ville.map(city => ({
                                    label: city.name,
                                    value: city.id,
                                  })) || []
                                ).map((item, key) => (
                                  <option key={key} value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              </TextField>
                            </fieldset>
                          </div>
                        </div>
                        <div className="row" style={{ alignItems: "center" }}>
                          <div className="col-md-6 col-lg-6 col-xs-12">
                            <fieldset className="text-center">
                              <CustomUploadPhoto
                                multiple
                                maxSize={5000000}
                                accept={{
                                  "image/*": [".png", ".jpeg", ".jpg"],
                                }}
                                onRemove={onRemoveFile}
                                onDrop={onUpload}
                                {...getFieldProps("entreprise_logo")}
                                value={image?.src || values.entreprise_logo}
                                logos={logos}
                                error={Boolean(
                                  touched.entreprise_logo &&
                                    errors.entreprise_logo
                                )}
                                helperText={
                                  touched.entreprise_logo &&
                                  errors.entreprise_logo
                                }
                              />
                            </fieldset>
                          </div>
                          <div className="col-md-6 col-lg-6 col-xs-12">
                            <fieldset>
                              <label className="label-form">
                                Domaine d'activité *
                              </label>
                              <TextField
                                select
                                SelectProps={{ native: true }}
                                {...getFieldProps(
                                  "entreprise_domaine_activite"
                                )}
                                error={Boolean(
                                  touched.entreprise_domaine_activite &&
                                    errors.entreprise_domaine_activite
                                )}
                                helperText={
                                  touched.entreprise_domaine_activite &&
                                  errors.entreprise_domaine_activite
                                }
                              >
                                <option value={null}>Domaine d'activité</option>
                                {(
                                  (sectors || []).map(sector => ({
                                    label: sector.name,
                                    value: sector.id,
                                  })) || []
                                ).map((item, key) => (
                                  <option key={key} value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              </TextField>
                            </fieldset>
                            <fieldset>
                              <label className="label-form">
                                Type d'entreprise *
                              </label>
                              <TextField
                                select
                                SelectProps={{ native: true }}
                                {...getFieldProps("entreprise_type_entreprise")}
                                error={Boolean(
                                  touched.entreprise_type_entreprise &&
                                    errors.entreprise_type_entreprise
                                )}
                                helperText={
                                  touched.entreprise_type_entreprise &&
                                  errors.entreprise_type_entreprise
                                }
                              >
                                <option value={null}>Type d'entreprise</option>
                                {(
                                  (agence_types || []).map(type => ({
                                    label: type.name,
                                    value: type.id,
                                  })) || []
                                ).map((item, key) => (
                                  <option key={key} value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              </TextField>
                            </fieldset>
                          </div>
                        </div>
                      </div>
                      <h3 className="titre3_section">
                        Coordonnées <span>de facturation</span>
                      </h3>
                      <div className="content_interne">
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-xs-12">
                            <fieldset>
                              <label className="label-form">
                                Adresse du siège *
                              </label>
                              <TextField
                                {...getFieldProps(
                                  "entreprise_facture_adresse_siege"
                                )}
                                error={Boolean(
                                  touched.entreprise_facture_adresse_siege &&
                                    errors.entreprise_facture_adresse_siege
                                )}
                                helperText={
                                  touched.entreprise_facture_adresse_siege &&
                                  errors.entreprise_facture_adresse_siege
                                }
                              />
                            </fieldset>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-xs-12">
                            <fieldset>
                              <label className="label-form">
                                Complément d'adresse
                              </label>
                              <TextField
                                {...getFieldProps(
                                  "entreprise_facture_adresse_complement"
                                )}
                              />
                            </fieldset>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4 col-lg-4 col-xs-12">
                            <fieldset>
                              <label className="label-form">
                                Code postal *
                              </label>
                              <TextField
                                {...getFieldProps("entreprise_facture_cp")}
                                error={Boolean(
                                  touched.entreprise_facture_cp &&
                                    errors.entreprise_facture_cp
                                )}
                                helperText={
                                  touched.entreprise_facture_cp &&
                                  errors.entreprise_facture_cp
                                }
                                inputProps={{ maxLength: 5, type: "number" }}
                              />
                            </fieldset>
                          </div>
                          <div className="col-md-4 col-lg-4 col-xs-12">
                            <fieldset>
                              <label className="label-form">Ville *</label>
                              <TextField
                                {...getFieldProps("entreprise_facture_ville")}
                                error={Boolean(
                                  touched.entreprise_facture_ville &&
                                    errors.entreprise_facture_ville
                                )}
                                helperText={
                                  touched.entreprise_facture_ville &&
                                  errors.entreprise_facture_ville
                                }
                              />
                            </fieldset>
                          </div>
                          <div className="col-md-4 col-lg-4 col-xs-12">
                            <fieldset>
                              <label className="label-form">Pays *</label>
                              <TextField
                                {...getFieldProps("entreprise_facture_pays")}
                                error={Boolean(
                                  touched.entreprise_facture_pays &&
                                    errors.entreprise_facture_pays
                                )}
                                helperText={
                                  touched.entreprise_facture_pays &&
                                  errors.entreprise_facture_pays
                                }
                              />
                            </fieldset>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4 col-lg-4 col-xs-12">
                            <fieldset>
                              <label className="label-form">Contact *</label>
                              <TextField
                                placeholder="Nom *"
                                {...getFieldProps(
                                  "entreprise_facture_contact_nom"
                                )}
                                error={Boolean(
                                  touched.entreprise_facture_contact_nom &&
                                    errors.entreprise_facture_contact_nom
                                )}
                                helperText={
                                  touched.entreprise_facture_contact_nom &&
                                  errors.entreprise_facture_contact_nom
                                }
                              />
                            </fieldset>
                          </div>
                          <div className="col-md-4 col-lg-4 col-xs-12">
                            <fieldset>
                              <label className="label-form label-vide">
                                &nbsp;
                              </label>
                              <TextField
                                placeholder="Prénom *"
                                {...getFieldProps(
                                  "entreprise_facture_contact_prenom"
                                )}
                                error={Boolean(
                                  touched.entreprise_facture_contact_prenom &&
                                    errors.entreprise_facture_contact_prenom
                                )}
                                helperText={
                                  touched.entreprise_facture_contact_prenom &&
                                  errors.entreprise_facture_contact_prenom
                                }
                              />
                            </fieldset>
                          </div>
                          <div className="col-md-4 col-lg-4 col-xs-12">
                            <fieldset>
                              <label className="label-form label-vide">
                                &nbsp;
                              </label>
                              <TextField
                                select
                                SelectProps={{ native: true }}
                                {...getFieldProps(
                                  "entreprise_facture_contact_fonction"
                                )}
                              >
                                <option value={null}>Fonction</option>
                                {(
                                  (fonctions || []).map(fonction => ({
                                    label: fonction.name,
                                    value: fonction.id,
                                  })) || []
                                ).map((item, key) => (
                                  <option key={key} value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              </TextField>
                            </fieldset>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 col-lg-6 col-xs-12">
                            <fieldset>
                              <label className="label-form">Email</label>
                              <TextField
                                {...getFieldProps(
                                  "entreprise_facture_contact_email"
                                )}
                                error={Boolean(
                                  touched.entreprise_facture_contact_email &&
                                    errors.entreprise_facture_contact_email
                                )}
                                helperText={
                                  touched.entreprise_facture_contact_email &&
                                  errors.entreprise_facture_contact_email
                                }
                              />
                            </fieldset>
                          </div>
                          <div className="col-md-6 col-lg-6 col-xs-12">
                            <fieldset>
                              <label className="label-form">Téléphone</label>
                              <TextField
                                {...getFieldProps(
                                  "entreprise_facture_contact_phone"
                                )}
                                error={Boolean(
                                  touched.entreprise_facture_contact_phone &&
                                    errors.entreprise_facture_contact_phone
                                )}
                                helperText={
                                  touched.entreprise_facture_contact_phone &&
                                  errors.entreprise_facture_contact_phone
                                }
                              />
                            </fieldset>
                          </div>
                        </div>
                      </div>
                      <div className="content_bouton_valider">
                        <Button className="bouton_valider" type="submit">
                          Valider
                        </Button>
                      </div>
                    </div>
                  </Form>
                </FormikProvider>
              </div>
            </LoadingOverlay>
          </div>
        </div>
      </div>
    </>
  )
}
export default CreerCompte
