import React from "react"
import { useDropzone } from "react-dropzone"
import { Box, Button } from "@material-ui/core"

const calcArray = data => [
  ...Array(data.length < 3 ? data.length + 1 : data.length),
]

export default function CustomUploadFile(props) {
  const { label, value, placeholder, logos, onRemove, helperText } = props
  const ERROR = {
    "file-invalid-type": "Fichier Image seulement",
    "file-too-large": `Fichier trop large 5MB max`,
  }
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    ...props,
    multiple: false,
  })
  const handleError = message => {
    if (ERROR[message]) {
      return ERROR[message]
    }
    return "Erreur lors de l'upload du fichier"
  }
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignContent: "center" }}
    >
      <span>{label}</span>

      {calcArray(logos).map((item, index) => (
        <Box
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 400,
          }}
          style={{ width: "100%", cursor: "pointer" }}
          key={index}
        >
          <div
            style={{ width: "100%", position: "relative", overflow: "hidden" }}
          >
            <div className="icon-photo">
              <div className="css-h41uko">
                <div
                  {...getRootProps({
                    className: `upload-root-zone ${
                      logos && logos[index] && "upload-root-zone-bis"
                    }`,
                  })}
                >
                  <input {...getInputProps()} />
                </div>
                {logos && logos[index] && (
                  <Box
                    component="img"
                    alt="avatar"
                    src={logos[index].src}
                    sx={{ zIndex: 8, objectFit: "cover" }}
                  />
                )}
                <div
                  className={`icon ${logos && logos[index] && "icon-hover"}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon-svg"
                    focusable="false"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                    style={{ transform: "rotate(360deg)" }}
                  >
                    <path
                      d="M3 8c0 .55.45 1 1 1s1-.45 1-1V6h2c.55 0 1-.45 1-1s-.45-1-1-1H5V2c0-.55-.45-1-1-1s-1 .45-1 1v2H1c-.55 0-1 .45-1 1s.45 1 1 1h2v2z"
                      fill={
                        (logos && logos[index] && "#ffffff") || "currentColor"
                      }
                    ></path>
                    <circle
                      cx="13"
                      cy="14"
                      r="3"
                      fill={
                        (logos && logos[index] && "#ffffff") || "currentColor"
                      }
                    ></circle>
                    <path
                      fill={
                        (logos && logos[index] && "#ffffff") || "currentColor"
                      }
                      d="M21 6h-3.17l-1.24-1.35A1.99 1.99 0 0 0 15.12 4h-6.4c.17.3.28.63.28 1c0 1.1-.9 2-2 2H6v1c0 1.1-.9 2-2 2c-.37 0-.7-.11-1-.28V20c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5s-2.24 5-5 5z"
                    ></path>
                  </svg>
                  <span
                    onClick={() => {
                      if (logos[index]) {
                        onRemove(index)
                      }
                    }}
                  >
                    {(logos[index] && "Supprimer") ||
                      `Ajouter ${(logos.length && "autre") || "votre"} logo`}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Box>
      ))}
      <p
        className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1qeri3d-MuiFormHelperText-root"
        style={{
          fontSize: "0.75rem",
          textAlign: "left",
          fontWeight: 400,
          color: "#FF4842",
          marginLeft: "14px",
          marginTop: "8px",
        }}
      >
        {(fileRejections.length &&
          fileRejections.map(({ file, errors }) => {
            return errors.map(e => handleError(e.code)).join(", ")
          })) ||
          helperText ||
          ""}
      </p>
    </Box>
  )
}
